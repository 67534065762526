<template>
  <Route :to="{ name: route.deposit }" :title="t('add_deposit')">
    <div class="casino-btn casino-btn-pink csn-nav-btn csn-nab add-deposit">
      <div class="total-balance">
        <ButtonLoader v-if="hasNoBalance" />
        <span v-else class="csn-nab-balance">{{ balance }}</span>
      </div>

      <span class="glyphicon glyphicon-plus-sign"></span>
    </div>
  </Route>
</template>
<script>
import { mapState } from 'vuex'
import {
  NAVBAR__ACCOUNT_BALANCE,
  RouteName,
  Module,
  CASINO_INFO,
} from '@/constants'
import { isNil } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: NAVBAR__ACCOUNT_BALANCE,
  components: {
    Route: () => import('@/components/Route'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
  },
  computed: {
    ...mapState(Module.CASINO_INFO, { CASINO_INFO }),
    route: () => ({
      deposit: RouteName.DEPOSIT,
    }),
    balance() {
      return CasinoInfo.getAccountBalance(this.CASINO_INFO)
    },
    hasNoBalance() {
      return isNil(this.balance)
    },
    t() {
      return this.$createComponentTranslator(NAVBAR__ACCOUNT_BALANCE)
    },
  },
}
</script>
